import dynamic from "next/dynamic";
import {SVGProps} from "react";

const AUFlag = dynamic(() => import('public/flags/AU.svg'));
const BRFlag = dynamic(() => import('public/flags/BR.svg'));
const CAFlag = dynamic(() => import('public/flags/CA.svg'));
const CNFlag = dynamic(() => import('public/flags/CN.svg'));
const DEFlag = dynamic(() => import('public/flags/DE.svg'));
const ESFlag = dynamic(() => import('public/flags/ES.svg'));
const GBFlag = dynamic(() => import('public/flags/GB.svg'));
const MXFlag = dynamic(() => import('public/flags/MX.svg'));
const NLFlag = dynamic(() => import('public/flags/NL.svg'));
const SGFlag = dynamic(() => import('public/flags/SG.svg'));
const USFlag = dynamic(() => import('public/flags/US.svg'));
const ZAFlag = dynamic(() => import('public/flags/ZA.svg'));

type FlagProps = {
  type: string,
  className?: string,
} & SVGProps<any>;


/**
 * Returns an Svg Country Flag.
 *
 * Check the switch statement to see the available types.
 *
 * @param type
 *   The flag type
 * @param props
 *   Additional properties to pass to the svg.
 * @constructor
 */
export function Flag({type, ...props}: FlagProps) {
  let icon;
  return (() => {
    switch (type) {
      case "AU":
        icon = <AUFlag {...props}/>
        break;
      case "BR":
        icon = <BRFlag {...props}/>
        break;
      case "CA":
        icon = <CAFlag {...props}/>
        break;
      case "CN":
        icon = <CNFlag {...props}/>
        break;
      case "DE":
        icon = <DEFlag {...props}/>
        break;
      case "ES":
        icon = <ESFlag {...props}/>
        break;
      case "GB":
        icon = <GBFlag {...props}/>
        break;
      case "MX":
        icon = <MXFlag {...props}/>
        break;
      case "NL":
        icon = <NLFlag {...props}/>
        break;
      case "SG":
        icon = <SGFlag {...props}/>
        break;
      case "US":
        icon = <USFlag {...props}/>
        break;
      case "ZA":
        icon = <ZAFlag {...props}/>
        break;
      default:
        break;
    }
    return icon ?? <></>;
  })();
}
